<template>
  <div class="toggle-input">
    <label class="switch">
      <input v-model="innerValue" type="checkbox" class="hidden" :aria-label="ariaLabel" />
      <span class="slider round"></span>
    </label>
  </div>
</template>
<script setup lang="ts">
import { debounce, isEqual } from 'lodash-es';

const props = defineProps({
  modelValue: {
    type: [Boolean, String, Object, Array],
    required: true,
  },
  ariaLabel: {
    type: String,
    default: '',
  },
  trueValue: {
    type: [String, Object, Number, Boolean],
    default: true,
  },
  falseValue: {
    type: [String, Object, Number, Boolean, Array],
    default: false,
  },
});
const emit = defineEmits(['input']);
const innerValue = ref<boolean>(isEqual(props.modelValue, props.trueValue));

watch(
  innerValue,
  debounce(newValue => {
    if (newValue) {
      emit('input', props.trueValue);
    } else {
      emit('input', props.falseValue);
    }
  }, 200),
);

watch(
  () => props.modelValue,
  debounce(newValue => {
    if (Array.isArray(newValue) && newValue.length) {
      innerValue.value = isEqual(newValue[0], props.trueValue);
      return;
    }

    innerValue.value = isEqual(newValue, props.trueValue);
  }, 200),
);
</script>
<style lang="postcss" scoped>
.toggle-input {
  display: flex;
  align-items: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  transform: rotateY(180deg);
}
/* The slider */
.slider {
  @apply border border-primary-300 bg-primary-1-10;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

.slider:before {
  @apply bg-white;
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  right: 1px;
  bottom: 2px;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}

input:checked + .slider:before {
  @apply bg-white;

  -webkit-transform: translateX(-25px);
  -ms-transform: translateX(-25px);
  transform: translateX(-25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  @apply bg-primary-1-100;
}
</style>
